export function createFilter<Operator extends keyof FilterOperators = keyof FilterOperators>({
	field,
	operator,
	value,
	dateAuxiliary,
}: Filter<Operator>): Filter<Operator> {
	return {
		field,
		operator,
		value,
		dateAuxiliary,
	};
}
