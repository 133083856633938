import { StateCreator } from 'zustand/vanilla';
import { definePersistentQueryParamStore, PersistQueryParamsConfig } from '@warehouse/shared/util';
import React, { useMemo } from 'react';

interface ListRelationshipStore {
	mode: 'parents' | 'children';
	actions: {
		setMode: (mode: 'parents' | 'children') => void;
	};
}

const listRelationshipStoreStateCreator: StateCreator<ListRelationshipStore> = (set, get) => ({
	mode: 'children',
	actions: {
		setMode: (mode: 'parents' | 'children') => set({ mode }),
	},
});

const [InternalListRelationshipStoreProvider, useListRelationshipStore] =
	definePersistentQueryParamStore<ListRelationshipStore>(listRelationshipStoreStateCreator);

export const listRelationshipStoreSelector = {
	actions: (state: ListRelationshipStore) => state.actions,
	mode: (state: ListRelationshipStore) => state.mode,
};

export function ListRelationshipStoreProvider({ children }: { children: React.ReactNode }) {
	const storeConfig = useMemo(
		(): PersistQueryParamsConfig<ListRelationshipStore> => ({
			mode: {
				type: 'string',
				keyName: 'relationship-list-mode',
				defaultValue: 'children',
			},
		}),
		[],
	);

	return <InternalListRelationshipStoreProvider config={storeConfig}>{children}</InternalListRelationshipStoreProvider>;
}

export { useListRelationshipStore };
