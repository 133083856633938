import React, { useRef, useState } from 'react';
import { CellContext } from '@tanstack/react-table';
import { Rating } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Basic';
import { Inherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';

// LIBRARY
import { DropdownV2 } from '@warehouse/shared/ui';
import { isInherited } from '@warehouse/title/core';

// HOOKS
import { useTerritories } from '@warehouse/global-entities/core';
import { WithUuid } from '../../../../src/views/title/hooks/useTitleRecordsAutoSave/recordToArray';
import useRatingSystemTerritoriesOptions from '../../../../src/views/title/hooks/useRatingSystemTerritoriesOptions';
import { CellWrapper } from '../../../../src/components/library/SimpleTable/style';

// COMPONENTS
import WillClearFieldsConfirmModal from '../../../../src/components/titles/WillClearFieldsConfirmModal';

function CellTerritory(info: CellContext<WithUuid<Inherited<Rating>>, WithUuid<Inherited<Rating>>>) {
	const { getKey } = useTerritories();
	const [openModal, setOpenModal] = useState(false);
	const { getValue, table } = info;
	const editRecord = table.options?.meta?.customPropsCell?.editRecord;
	const confirmRef = useRef<() => void>();

	const territories = useRatingSystemTerritoriesOptions();

	const onChange = (e: string) => {
		confirmRef.current = () => {
			editRecord?.({
				...getValue(),
				displayValue: {
					...getValue()?.displayValue,
					region: {
						[getKey(e || '')]: e,
					},
					system: undefined,
					value: undefined,
					reasons: [],
					descriptions: [],
				},
			});
			setOpenModal(false);
		};

		const { system, value, reasons, descriptions } = getValue()?.displayValue || {};

		if (system || value || (reasons?.length || []) > 0 || (descriptions || [])?.length > 0) {
			setOpenModal(true);
		} else {
			confirmRef.current?.();
		}
	};

	return (
		<>
			<CellWrapper>
				<DropdownV2
					variant={isInherited({ inheritedObject: getValue() }).isInherited ? 'prefilled' : 'default'}
					autoFocus={Object.keys(getValue().displayValue.region).length === 0}
					withSearch
					value={getValue()?.displayValue?.region?.country || getValue()?.displayValue?.region?.countryRegion || ''}
					options={territories}
					onChange={onChange}
					backgroundColor="transparent"
					transparentBorder
				/>
			</CellWrapper>
			<WillClearFieldsConfirmModal
				open={openModal}
				handleClose={() => setOpenModal(false)}
				onConfirm={() => confirmRef?.current?.()}
			/>
		</>
	);
}

export default CellTerritory;
