import { alpha, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { RelationshipType } from '@warehouse/title/core';
import { getRelationshipTypeObject } from '@warehouse/title/infra';
import { DropdownV2 } from '@warehouse/shared/ui';
import { FieldWrapper } from '../../ui/FieldWrapper';
import { ReadOnlyField } from '../../../../src/components/library/ReadOnlyField';
import ConfirmationModal from '../../../../src/components/library/ConfirmationModal';
import { shouldRequireConfirmationForPrimaryUpdate } from '../parent-relationship.core';

export function PrimaryField({ primary, primaryChange, commit, relationshipType }: PrimaryFieldProps) {
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
	const selectedPrimaryValueRef = useRef<boolean | undefined>(undefined);
	const isReadOnly = [RelationshipType.EpisodeOf, RelationshipType.SeasonOf].includes(relationshipType) && primary;

	const readOnlyFieldPrimary = (
		<div>
			<ReadOnlyField>{primary ? 'True' : 'False'}</ReadOnlyField>
			<TypographyStyled variant="buttonMediumRegular">
				{`The primary relationship can be changed by setting primary on another "${
					getRelationshipTypeObject(relationshipType).label
				}" relationship.`}
			</TypographyStyled>
		</div>
	);

	const onChangePrimary = (valueSelected: boolean) => {
		if (valueSelected && shouldRequireConfirmationForPrimaryUpdate(primary)) {
			setOpenConfirmationModal(true);
			selectedPrimaryValueRef.current = valueSelected;
			return;
		}
		primaryChange(valueSelected);
	};

	const label = 'Primary';

	return (
		<FieldWrapper className="field-wrapper">
			<Typography variant="buttonMediumMedium" color="text.primary">
				{label}
			</Typography>
			{isReadOnly && readOnlyFieldPrimary}
			{!isReadOnly && (
				<DropdownV2
					ariaLabel={label}
					value={primary}
					readOnly={openConfirmationModal}
					onBlur={commit}
					options={options}
					onChange={onChangePrimary}
					disabledMargin
				/>
			)}
			<ConfirmationModal
				title="Are you sure?"
				open={openConfirmationModal}
				onConfirm={() => {
					if (selectedPrimaryValueRef.current === undefined) {
						throw Error('Selected primary value is undefined when it should be true or false');
					}
					primaryChange(selectedPrimaryValueRef.current);
					commit();
					setOpenConfirmationModal(false);
					selectedPrimaryValueRef.current = undefined;
				}}
				onClose={() => {
					setOpenConfirmationModal(false);
					selectedPrimaryValueRef.current = undefined;
				}}
				message={`Are you sure you wish to proceed? This will remove the current primary "${
					getRelationshipTypeObject(relationshipType).label
				}"`}
			/>
		</FieldWrapper>
	);
}

const options = [
	{
		value: true,
		label: 'True',
	},
	{
		value: false,
		label: 'False',
	},
];

const TypographyStyled = styled(Typography)(
	({ theme }) => css`
		color: ${alpha(theme.palette.text.secondary, 0.5)} !important;
	`,
);

type PrimaryFieldProps = {
	primary: boolean | undefined;
	primaryChange: (primary: boolean) => void;
	commit: () => void;
	relationshipType: RelationshipType;
};
