import { useEffect } from 'react';
import { ApolloError, gql, useQuery } from '@apollo/client';
import { SearchInput } from '../../../graphql/codegen/graphql';
import mockTableDataProfilesJSON from '../../../views/profiles/list/mockTableData.json';

// TODO : These functions are mocked. Need to be implemented

interface UseSearchProfilesProps {
	baseSearchParams: SearchInput;
	debouncedSearch: string;
	filtering?: FilterStep;
	pollInterval: number;
}

function useSearchProfilesAdmin({
	baseSearchParams,
	debouncedSearch,
	filtering,
	pollInterval,
}: UseSearchProfilesProps) {
	const {
		loading,
		// error,
		// data,
		networkStatus,
		startPolling,
		stopPolling,
		refetch,
	} = useQuery<
		{
			searchProfilesAdmin: any; // TODO : Change this any
		},
		{
			search: SearchInput;
		}
	>(
		gql`
			query {
				test
			}
		`, // TODO : Need to be implemented
		{
			variables: {
				search: baseSearchParams,
			},
			notifyOnNetworkStatusChange: true,
		},
	);

	// Fire search after search input or filtering
	useEffect(() => {
		const searchNoFilterStep: FilterStep = {
			step: {
				combinationOperator: 'OR',
				filters: [
					{
						field: 'id',
						operator: 'contains',
						value: debouncedSearch,
					},
					{
						field: 'name',
						operator: 'contains',
						value: debouncedSearch,
					},
					{
						field: 'platform',
						operator: 'contains',
						value: debouncedSearch,
					},
					{
						field: 'specification',
						operator: 'contains',
						value: debouncedSearch,
					},
					{
						field: 'territory',
						operator: 'contains',
						value: debouncedSearch,
					},
					{
						field: 'tier',
						operator: 'contains',
						value: debouncedSearch,
					},
					{
						field: 'msr',
						operator: 'contains',
						value: debouncedSearch,
					},
					{
						field: 'isDefault',
						operator: 'contains',
						value: debouncedSearch,
					},
					{
						field: 'tags',
						operator: 'contains',
						value: debouncedSearch,
					},
					{
						field: 'status',
						operator: 'contains',
						value: debouncedSearch,
					},
				],
			},
		};

		const searchFilterStep: FilterStep = {
			step: {
				combinationOperator: 'AND',
				filters: [searchNoFilterStep, filtering!],
			},
		};

		if (debouncedSearch.length)
			refetch({
				search: {
					pagination: { page: 1, perPage: 1000 },
					filters: filtering ? JSON.stringify(searchFilterStep) : JSON.stringify(searchNoFilterStep),
				},
			});
		else
			refetch({
				search: {
					pagination: { page: 1, perPage: 1000 },
					filters: filtering ? JSON.stringify(filtering) : undefined,
				},
			});
	}, [debouncedSearch, filtering]);

	// Query polling
	useEffect(() => {
		startPolling(pollInterval);
	}, [startPolling]);

	return {
		loading,
		error: undefined as unknown as ApolloError,
		data: { searchProfilesAdmin: { documents: mockTableDataProfilesJSON } },
		startPolling,
		stopPolling,
		refetch,
		networkStatus,
	};
}

export default useSearchProfilesAdmin;
