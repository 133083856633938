import { editUuid, manifestationUuid, supplementalUuid } from '../infra';

export const defaultFilters: FilterStep = {
	step: {
		combinationOperator: 'AND',
		filters: [
			{
				field: 'titleStatus',
				operator: 'doesNotEqual',
				value: 'hidden',
			},
			{
				step: {
					filters: [
						{
							field: 'workType',
							operator: 'doesNotEqual',
							value: editUuid(),
						},
						{
							field: 'workType',
							operator: 'doesNotEqual',
							value: manifestationUuid(),
						},
						{
							field: 'workType',
							operator: 'doesNotEqual',
							value: supplementalUuid(),
						},
					],
					combinationOperator: 'AND',
				},
			},
		],
	},
};
