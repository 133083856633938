import React, { ReactNode, useMemo } from 'react';
import { PaginatorProvider, PaginatorStore, SearchProvider, SearchStore } from '@warehouse/shared/ui';
import { PersistQueryParamsConfig } from '../../shared/util/persist-query-param-store';
import { ListRelationshipStoreProvider } from './list-relationship.store';

export function RelationshipListStoreConfig({ children }: { children: ReactNode }) {
	const searchStoreConfig = useMemo(
		(): PersistQueryParamsConfig<SearchStore> => ({
			searchValue: {
				defaultValue: '',
			},
		}),
		[],
	);

	const paginationStoreConfig = useMemo(
		(): PersistQueryParamsConfig<PaginatorStore> => ({
			page: {
				type: 'number',
				defaultValue: 1,
			},
			perPage: {
				type: 'number',
				defaultValue: 10,
				sessionStorageKeyPrefix: 'relationship-list',
			},
		}),
		[],
	);

	return (
		<SearchProvider config={searchStoreConfig}>
			<PaginatorProvider config={paginationStoreConfig}>
				<ListRelationshipStoreProvider>{children}</ListRelationshipStoreProvider>
			</PaginatorProvider>
		</SearchProvider>
	);
}
