import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
	CellContext,
	ColumnDef,
	ColumnOrderState,
	ColumnSizingState,
	ExpandedState,
	flexRender,
	getCoreRowModel,
	getExpandedRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	OnChangeFn,
	Row,
	SortingState,
	useReactTable,
	VisibilityState,
} from '@tanstack/react-table';
import { Maybe } from 'graphql/jsutils/Maybe';
import { Divider, IconButton, MenuList, Typography } from '@mui/material';
import styled, { css } from 'styled-components';
import Icon from '@mdi/react';
import { mdiChevronDown, mdiChevronUp, mdiDotsVertical, mdiEyeOff, mdiFilterVariant, mdiPin } from '@mdi/js';
import { NetworkStatus } from '@apollo/client';
import { ResizeHandle, Table, TBody, TH, THead, TR } from './TableUtils';
import {
	ColumnStickyState,
	getColumnsTrueSize,
	getScrollPadding,
	getStickyPosition,
} from '../../utils/table/stickyUtils';
import { getCompleteVisibilityState } from '../../utils/table/visibilityUtils';
import ModalMenu, { MenuListItem } from '../library/ModalMenu';
import SortModal from './SortModal';
import SettingsModal from './SettingsModal';
import TableActions from './TableActions';
import BottomTablePagination from './BottomTablePagination';
import { FilterField, FilterStepWithId } from '../../utils/table/filtersUtils';
import FiltersModal from './FiltersModal';
import { FilterSortModalItem } from '../../types/table';
import SimpleRow from './SimpleRow';
import ExpandableRow from './ExpandableRow';
import NxLoader from '../library/NxLoader';
import ConfirmationModal from '../library/ConfirmationModal';
import deepEquality from '../../utils/deepEquality';
import { useSessionShareableParams } from '../../utils/hooks/useSessionShareableParams';
import { getCompleteOrderState } from '../../utils/table/orderUtils';

type PaginationProps = {
	pageSize: number | undefined;
	onPageSizeChange: (newPageSize: number) => void;
	currentPage: number | undefined;
	pageCount: number | undefined;
	onPageChange: (newPage: number) => void;
};

type PropsType<T> = {
	data?: Maybe<T>[];
	height?: number;
	maxHeight?: number;
	filtering?: FilterStep;
	setFiltering: (value?: FilterStep) => void;
	resetFilter?: () => void;
	showResetFilterButton?: boolean;
	sorting?: SortingState;
	setSorting?: (value: SortingState) => void;
	resetSort?: () => void;
	showResetSortButton?: boolean;
	filterFields: FilterField[];
	networkStatus?: NetworkStatus;
	columns: ColumnDef<Maybe<T>>[];
	initialSorting: { id: string; desc: boolean }[];
	onClickRow?: (row: Row<Maybe<T>>, e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void;
	backendPagination?: PaginationProps;
	sortModalItems?: FilterSortModalItem[];
	totalDocuments?: number;
	expandedByDefault?: boolean;
	initialVisibility?: VisibilityState;
	fullTableStyle?: 'default' | 'border';
	rowLink?: (row: Row<Maybe<T>>) => string;
	initialColumnSticky?: ColumnStickyState;
	getSortableColumnKeyById?: (id: string) => string;
	storageUniqueKey?: string;
	onColumnVisibilityChange?: (columnVisibility: VisibilityState) => void;
};

const TopWrapper = styled.div<{ height?: number; maxHeight?: number }>`
	display: flex;
	flex-direction: column;
	overflow: hidden;

	${({ height, maxHeight }) =>
		maxHeight
			? css`
					max-height: ${maxHeight}px;
			  `
			: css`
					height: ${height}px;
			  `}
`;

const TableWrapper = styled.div<{
	scrollPadding?: number;
	fullTableStyle?: 'default' | 'border';
}>(
	({ theme, scrollPadding, fullTableStyle }) => css`
		height: 100%;
		overflow: auto;

		${fullTableStyle === 'border'
			? css`
					border: 1px solid ${theme.palette.light.backgroundAlt};
					border-radius: 8px;
			  `
			: null}

		${scrollPadding
			? css`
					scroll-padding: ${scrollPadding}px;
					scroll-snap-type: x mandatory;
			  `
			: null}
	
	white-space: nowrap;
	`,
);

const HeaderTextWrapper = styled.div(
	() => css`
		align-items: center;
		display: flex;
	`,
);

const LoaderWrapper = styled.div(
	() => css`
		align-items: center;
		display: flex;
		height: 100%;
		justify-content: center;
		width: 100%;
	`,
);

const HeaderModalMenu = styled.div(
	({ theme }) => css`
		margin-left: auto;
		svg {
			color: ${theme.palette.action.divider};
		}
	`,
);

function WrappedTypographyCell({ getValue }: CellContext<any, any>) {
	return (
		<Typography variant="tableCell" style={{ fontWeight: 'inherit' }}>
			{getValue()}
		</Typography>
	);
}

function deleteRecursivelyIds(a: unknown): unknown {
	if (a === null || typeof a !== 'object') {
		return a;
	}
	if (Array.isArray(a)) {
		return a.map((item) => deleteRecursivelyIds(item));
	}
	const res: Record<string, unknown> = {};
	Object.keys(a).forEach((key) => {
		if (key !== 'id') {
			res[key] = deleteRecursivelyIds((a as Record<string, unknown>)[key]);
		}
	});
	return res;
}

function deepEqualityIgnoringId(
	a: FilterStepWithId | FilterStep | undefined,
	b: FilterStepWithId | FilterStep | undefined,
): boolean {
	const aClone = structuredClone(a);
	if (aClone?.id) delete aClone.id;
	const bClone = structuredClone(b);
	if (bClone?.id) delete bClone.id;
	return deepEquality(deleteRecursivelyIds(a), deleteRecursivelyIds(b));
}

function getColumnIsSorted(sortingKey: string, sorting: SortingState | undefined) {
	if (!sorting) return false;
	const sortState = sorting.find((sort) => sort.id === sortingKey);
	if (!sortState) return false;
	return sortState.desc ? 'desc' : 'asc';
}

function FullTable<T>(props: PropsType<T>) {
	type RowWithSubrows<D> = {
		subRows?: Maybe<D>[];
		title: string;
	};

	const {
		columns,
		data,
		height,
		maxHeight,
		filtering,
		setFiltering,
		resetFilter,
		showResetFilterButton,
		filterFields,
		networkStatus,
		initialSorting,
		onClickRow,
		backendPagination,
		sortModalItems,
		sorting,
		setSorting,
		resetSort,
		showResetSortButton,
		totalDocuments,
		initialVisibility,
		fullTableStyle = 'default',
		expandedByDefault = false,
		rowLink,
		initialColumnSticky,
		getSortableColumnKeyById = (id: string) => id,
		storageUniqueKey,
		onColumnVisibilityChange,
	} = props;
	const memoizedEmptyArray = useMemo(() => [], []);

	const initialTableState: {
		columnSizing: ColumnSizingState;
		sorting: SortingState;
		columnVisibility: VisibilityState;
		columnOrder: ColumnOrderState;
		columnSticky: ColumnStickyState;
	} = {
		columnSizing: {},
		sorting: initialSorting,
		columnVisibility: useMemo(
			() => getCompleteVisibilityState(columns, initialVisibility || {}),
			[columns, initialVisibility],
		),
		columnOrder: useMemo(() => getCompleteOrderState(columns), [columns]),
		columnSticky: initialColumnSticky !== undefined ? initialColumnSticky : memoizedEmptyArray,
	};
	const [openSort, setOpenSort] = useState<boolean>(false);
	const [openSettings, setOpenSettings] = useState<boolean>(false);
	const [columnSizing, setColumnSizing] = useState<ColumnSizingState>(initialTableState.columnSizing);
	const [internalSorting, setInternalSorting] = useState<SortingState>(initialTableState.sorting);
	const [selectedColumn, setSelectedColumn] = useState<string | null>(null);
	const [columnsTrueSize, setColumnsTrueSize] = useState<number[]>([]);
	const [scrollPadding, setScrollPadding] = useState<number>(0);
	const tableEl = useRef<HTMLTableElement>(null);
	const tableWrapperEl = useRef<HTMLDivElement>(null);
	const [modalMenuAnchorEl, setModalMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [openMenuModal, setOpenMenuModal] = useState<boolean>(false);
	const [columnValueModal, setColumnValueModal] = useState<string>('');
	const [openFilterModal, setOpenFilterModal] = useState<boolean>(false);
	const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);
	const [isScrolled, setIsScrolled] = useState<boolean>(false);
	const confirmFnRef = useRef(() => {});

	useEffect(() => {
		const handleScroll = () => {
			setIsScrolled(!!(tableWrapperEl?.current?.scrollLeft || -1 > 0));
		};

		tableWrapperEl?.current?.addEventListener?.('scroll', handleScroll);

		return () => {
			tableWrapperEl?.current?.removeEventListener('scroll', handleScroll);
		};
	}, [setIsScrolled, tableWrapperEl?.current]);

	// Loading
	const [isTableLoading, setIsTableLoading] = useState<boolean>(true);

	useEffect(() => {
		if (networkStatus === NetworkStatus.loading || networkStatus === NetworkStatus.setVariables) {
			setIsTableLoading(true);
		} else if (networkStatus !== NetworkStatus.refetch) {
			setIsTableLoading(false);
		}
	}, [networkStatus]);

	const [columnVisibility, setColumnVisibility, resetColumnVisibility] = useSessionShareableParams<VisibilityState>({
		uniqueKey: storageUniqueKey,
		name: 'columnsVisibility',
		defaultValue: initialTableState.columnVisibility,
	});

	// If we need to improve performance, this can be changed to a mirrored state to save some re-renders
	useEffect(() => {
		onColumnVisibilityChange?.(columnVisibility);
	}, [columnVisibility]);

	const [columnOrder, setColumnOrder, resetColumnOrder] = useSessionShareableParams<ColumnOrderState>({
		uniqueKey: storageUniqueKey,
		name: 'columnsOrder',
		defaultValue: initialTableState.columnOrder,
	});
	const [columnSticky, setColumnSticky, resetColumnSticky] = useSessionShareableParams<ColumnStickyState>({
		uniqueKey: storageUniqueKey,
		name: 'columnsSticky',
		defaultValue: initialTableState.columnSticky,
	});

	const newColumns = useMemo(
		() =>
			columns.map((column) => ({
				...column,
				cell: column.cell ? column.cell : WrappedTypographyCell,
			})),
		[columns],
	);

	const [expanded, setExpanded] = React.useState<ExpandedState>({});

	const table = useReactTable({
		data: data || [],
		columns: newColumns,
		state: {
			sorting: sorting || internalSorting,
			columnVisibility,
			columnOrder,
			columnSizing,
			expanded,
		},
		getSubRows: (row) => (row as RowWithSubrows<T>)?.subRows,
		onExpandedChange: setExpanded,
		columnResizeMode: 'onChange',
		onSortingChange: setSorting ? undefined : setInternalSorting,
		onColumnVisibilityChange: setColumnVisibility as OnChangeFn<VisibilityState>,
		onColumnOrderChange: setColumnOrder as OnChangeFn<ColumnOrderState>,
		onColumnSizingChange: (sizingState) => {
			// Get actual size from DOM for more accuracy for Sticky columns
			setColumnsTrueSize(getColumnsTrueSize(tableEl));
			setColumnSizing(sizingState);
		},
		getCoreRowModel: getCoreRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		manualSorting: true,
		initialState: {
			pagination: {
				pageSize: backendPagination?.pageSize ?? 50,
			},
		},
		autoResetAll: false,
	});

	useEffect(() => {
		if (expandedByDefault && table) table.toggleAllRowsExpanded();
	}, [expandedByDefault]);

	// Set True column size after table load
	useEffect(() => {
		setColumnsTrueSize(getColumnsTrueSize(tableEl));
	}, [tableEl, tableEl.current, columnSticky, columnOrder, table.getState().pagination.pageIndex]);

	// Set Scroll padding after resize or stick
	useEffect(() => {
		setScrollPadding(getScrollPadding(table.getAllColumns(), columnSticky, columnsTrueSize));
	}, [columnsTrueSize, columnSticky]);

	const sortFieldMenuModal = (idColumn: string) => {
		let updatedSorting: SortingState = [];

		if (internalSorting.length === 0) {
			updatedSorting = [{ id: idColumn, desc: false }];
		} else if (internalSorting[0].id === idColumn) {
			if (!internalSorting[0].desc) {
				updatedSorting = [{ id: idColumn, desc: true }];
			} else {
				updatedSorting = [];
			}
		} else {
			updatedSorting = [{ id: idColumn, desc: false }];
		}

		setInternalSorting(updatedSorting);

		if (setSorting) setSorting(updatedSorting);
	};

	const hideColumnMenuModal = (idColumn: string) => {
		columnVisibility[idColumn] = false;
		setColumnVisibility({ ...columnVisibility });
		// Removing sticky if field is hidden
		if (columnSticky.includes(idColumn)) {
			const tmp = [...columnSticky];
			tmp.splice(tmp.indexOf(idColumn), 1);
			setColumnSticky(tmp);
		}
	};

	const freezeColumnMenuModal = (idColumn: string) => {
		if (!columnSticky.includes(idColumn)) {
			setColumnSticky([idColumn, ...columnSticky]);

			if (columnOrder.length) {
				const copy = [...columnOrder];

				if (copy.includes(idColumn)) {
					copy.splice(copy.indexOf(idColumn), 1);
				}
				copy.unshift(idColumn);
				setColumnOrder(copy);
			} else {
				const copy = table
					.getAllColumns()
					.filter((item) => columnVisibility[item.id])
					.map((item) => item.id);
				if (copy.includes(idColumn)) {
					copy.splice(copy.indexOf(idColumn), 1);
				}
				copy.unshift(idColumn);
				setColumnOrder(copy);
			}
		}
	};

	const unfreezeColumnMenuModal = (idColumn: string) => {
		if (columnSticky.includes(idColumn)) {
			const tmp = [...columnSticky];
			tmp.splice(tmp.indexOf(idColumn), 1);
			setColumnSticky(tmp);

			if (columnOrder.length) {
				const copy = [...columnOrder];

				if (copy.includes(idColumn)) {
					copy.splice(copy.indexOf(idColumn), 1);
				}
				copy.splice(columnSticky.length - 1, 0, idColumn);
				setColumnOrder(copy);
			} else {
				const copy = table
					.getAllColumns()
					.filter((item) => columnVisibility[item.id])
					.map((item) => item.id);
				if (copy.includes(idColumn)) {
					copy.splice(copy.indexOf(idColumn), 1);
				}
				copy.splice(columnSticky.length - 1, 0, idColumn);
				setColumnOrder(copy);
			}
		}
	};

	const resetTableSettings = () => {
		setColumnSizing(initialTableState.columnSizing);
		setInternalSorting(initialTableState.sorting);
		resetColumnVisibility();
		resetColumnOrder();
		resetColumnSticky();

		if (setSorting) setSorting(initialSorting);
	};

	const handleMenuModalButton = (columnId: string, anchorEl: HTMLButtonElement) => {
		setModalMenuAnchorEl(anchorEl);
		setOpenMenuModal((prevOpen) => !prevOpen);
		setColumnValueModal(columnId);
	};

	return (
		<TopWrapper height={height || 600} maxHeight={maxHeight}>
			{modalMenuAnchorEl && (
				<ModalMenu
					position="bottom-start"
					enableArrow={false}
					open={openMenuModal}
					onClose={() => setOpenMenuModal(false)}
					anchorEl={modalMenuAnchorEl}
					content={
						<MenuList>
							{table
								?.getAllColumns()
								?.find(({ id }) => id === columnValueModal)
								?.getCanSort() ? (
								<MenuListItem
									onClick={() => {
										sortFieldMenuModal(getSortableColumnKeyById(columnValueModal));
										setOpenMenuModal(false);
									}}
									icon={<Icon size="18px" path={mdiFilterVariant} />}
									text="Sort by this field"
								/>
							) : null}
							<MenuListItem
								onClick={() => {
									hideColumnMenuModal(columnValueModal);
									setOpenMenuModal(false);
								}}
								icon={<Icon size="18px" path={mdiEyeOff} />}
								text="Hide field"
							/>
							{columnSticky.includes(columnValueModal) ? (
								<MenuListItem
									onClick={() => {
										unfreezeColumnMenuModal(columnValueModal);
										setOpenMenuModal(false);
									}}
									icon={<Icon size="18px" path={mdiPin} />}
									text="Unpin field"
								/>
							) : (
								<MenuListItem
									onClick={() => {
										freezeColumnMenuModal(columnValueModal);
										setOpenMenuModal(false);
									}}
									icon={<Icon size="18px" path={mdiPin} />}
									text="Pin field"
								/>
							)}
						</MenuList>
					}
				/>
			)}

			{openSort && (
				<SortModal
					open={openSort}
					handleClose={(value) => {
						if (deepEquality(sorting, value)) {
							setOpenSort(false);
							return;
						}
						setOpenConfirmationModal(true);
						confirmFnRef.current = () => {
							setOpenSort(false);
							setOpenConfirmationModal(false);
						};
					}}
					items={sortModalItems || []}
					values={sorting || internalSorting}
					onSubmit={(value) => {
						setOpenSort(false);
						setInternalSorting(value);
						if (setSorting) {
							setSorting(value);
						}
					}}
				/>
			)}
			{openFilterModal && (
				<FiltersModal
					open={openFilterModal}
					handleClose={(value) => {
						if (deepEqualityIgnoringId(filtering, value)) {
							setOpenFilterModal(false);
							return;
						}
						setOpenConfirmationModal(true);
						confirmFnRef.current = () => {
							setOpenFilterModal(false);
							setOpenConfirmationModal(false);
						};
					}}
					filterState={filtering}
					fields={filterFields}
					onSubmit={(value) => {
						setOpenFilterModal(false);
						setFiltering(value);
					}}
				/>
			)}
			{openSettings && (
				<SettingsModal
					open={openSettings}
					handleClose={() => setOpenSettings(false)}
					visibilityState={columnVisibility}
					setVisibilityState={setColumnVisibility}
					setOrderState={setColumnOrder}
					columnSticky={columnSticky}
					setColumnSticky={setColumnSticky}
					columns={columns}
				/>
			)}
			<TableActions
				itemsNb={totalDocuments || (data ? data.length : 0)}
				onSortClick={() => setOpenSort(true)}
				resetSort={resetSort}
				showResetSortButton={showResetSortButton}
				onFilterClick={() => setOpenFilterModal(true)}
				resetFilter={resetFilter}
				showResetFilterButton={showResetFilterButton}
				onSettingsClick={() => setOpenSettings(true)}
				onResetClick={() => resetTableSettings()}
			/>
			{fullTableStyle === 'default' && <Divider />}
			{isTableLoading && (
				<LoaderWrapper>
					<NxLoader />
				</LoaderWrapper>
			)}

			{!isTableLoading && (
				<TableWrapper ref={tableWrapperEl} scrollPadding={scrollPadding} fullTableStyle={fullTableStyle}>
					<Table
						style={{
							width: '100%',
						}}
						ref={tableEl}
					>
						<THead>
							{table.getHeaderGroups().map((headerGroup) => (
								<TR key={headerGroup.id}>
									{headerGroup.headers.map((header) => (
										<TH
											onClick={() => {
												if (header.column.getCanSort()) {
													sortFieldMenuModal(getSortableColumnKeyById(header.column.id));
												}
											}}
											$pointer={header.column.getCanSort()}
											key={header.id}
											colSpan={header.colSpan}
											className={`
                      ${
												columnSticky.includes(header.column.id) &&
												columnSticky.indexOf(header.column.id) === columnSticky.length - 1 &&
												isScrolled
													? 'sticky-shadow'
													: ''
											} 
                      ${
												(!table.getState().columnSizingInfo.isResizingColumn && selectedColumn === header.column.id) ||
												table.getState().columnSizingInfo.isResizingColumn === header.column.id
													? 'selected'
													: ''
											}
                    `}
											style={{ width: header.getSize() }}
											sticky={
												columnSticky.includes(header.id)
													? getStickyPosition(
															table.getAllColumns(),
															header.column,
															columnOrder,
															columnsTrueSize,
															columnSticky,
													  )
													: undefined
											}
											onMouseEnter={() => setSelectedColumn(header.column.id)}
											onMouseLeave={() => setSelectedColumn(null)}
										>
											{header.isPlaceholder ? null : (
												// eslint-disable-next-line jsx-a11y/click-events-have-key-events
												<HeaderTextWrapper>
													{typeof header.column.columnDef.header === 'string' ? (
														<Typography
															variant="buttonSmallMedium"
															className={header.column.getCanSort() ? 'cursor-pointer select-none' : ''}
														>
															{flexRender(header.column.columnDef.header, header.getContext())}
														</Typography>
													) : (
														<div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
													)}

													{(() => {
														const isSorted = getColumnIsSorted(getSortableColumnKeyById(header.column.id), sorting);

														if (isSorted) {
															return <Icon path={isSorted === 'asc' ? mdiChevronUp : mdiChevronDown} size="18px" />;
														}
														return null;
													})()}

													<HeaderModalMenu>
														<IconButton
															onClick={(e) => {
																e.stopPropagation();
																handleMenuModalButton(header.column.id, e.target as HTMLButtonElement);
															}}
														>
															<Icon path={mdiDotsVertical} size="24px" />
														</IconButton>
													</HeaderModalMenu>
												</HeaderTextWrapper>
											)}
											<ResizeHandle
												{...{
													onMouseDown: header.getResizeHandler(),
													onTouchStart: header.getResizeHandler(),
													className: `resizer ${header.column.getIsResizing() ? 'isResizing' : ''}`,
												}}
											/>
										</TH>
									))}
								</TR>
							))}
						</THead>
						<TBody>
							{table.getRowModel().rows.map((row, index) => (
								<TR
									rowIndex={index}
									pointer={!!onClickRow}
									onClick={(e) => (onClickRow ? onClickRow(row, e) : () => {})}
									key={row.id}
									canExpand={row.getCanExpand()}
								>
									{row.getCanExpand() ? (
										<ExpandableRow<T> row={row} />
									) : (
										<SimpleRow<T>
											row={row}
											columnSticky={columnSticky}
											table={table}
											selectedColumn={selectedColumn}
											columnOrder={columnOrder}
											columnsTrueSize={columnsTrueSize}
											rowLink={rowLink}
											isScrolled={isScrolled}
										/>
									)}
								</TR>
							))}
						</TBody>
					</Table>
				</TableWrapper>
			)}
			<BottomTablePagination
				tableStyle={fullTableStyle}
				pageSize={backendPagination ? backendPagination.pageSize || 0 : table.getState().pagination.pageSize}
				onPageSizeChange={(value) => {
					backendPagination?.onPageSizeChange?.(value);
					table.setPageSize(value);
				}}
				currentPage={backendPagination ? backendPagination.currentPage || 0 : table.getState().pagination.pageIndex + 1}
				pageCount={backendPagination ? backendPagination.pageCount || 0 : table.getPageCount()}
				onPageChange={backendPagination ? backendPagination.onPageChange : (value) => table.setPageIndex(value - 1)}
			/>
			<ConfirmationModal
				open={openConfirmationModal}
				onClose={() => setOpenConfirmationModal(false)}
				onConfirm={() => {
					confirmFnRef.current?.();
					confirmFnRef.current = () => {};
				}}
				title="Are you sure?"
				message="You have unsaved changes. Are you sure you want to leave?"
			/>
		</TopWrapper>
	);
}

export default FullTable;
