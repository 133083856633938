import { isEqual } from 'lodash';
import { QueryParamsManagerImpl, QueryParamsManager } from '@warehouse/shared/util';
import { IndexableObject, PersistQueryParamsConfig, PersistQueryParamsKeyConfig, ValueType } from './types';

export abstract class StoreInterface<STORE extends IndexableObject> {
	abstract getItem(key: keyof STORE): ValueType | undefined;
	abstract setItem(key: keyof STORE, value: ValueType): void;
	// removeItem delete any stored value for a given key (both in query params and in session storage)
	abstract removeItem(key: keyof STORE): void;
	// hideItem only delete visible stored value for a given key (it only removes the query params)
	abstract hideItem(key: keyof STORE): void;
}

export function isValueEmpty(value: ValueType, fieldConfig: PersistQueryParamsKeyConfig): boolean {
	switch (fieldConfig.type) {
		case 'boolean':
			return value === undefined || value === null;
		case 'number':
			return value === undefined || value === null;
		case 'array':
			return value === undefined || value === null;
		case 'object':
			return value === undefined || value === null || Object.keys(value).length === 0;
		case 'string':
		default:
			return value === undefined || value === null || value === '';
	}
}

export function deserializeValue(value: string | null, fieldConfig: PersistQueryParamsKeyConfig): ValueType {
	switch (fieldConfig.type) {
		case 'boolean':
			return value !== null && value !== undefined && value !== 'false';
		case 'number':
			if (value === null) return 0;
			return parseInt(value, 10);
		case 'array':
			if (value === null) return [];
			return JSON.parse(atob(value));
		case 'object':
			if (value === null) return {};
			return JSON.parse(atob(value));
		case 'string':
		default:
			return value ?? '';
	}
}

export function serializeValue(value: ValueType, fieldConfig: PersistQueryParamsKeyConfig): string {
	switch (fieldConfig.type) {
		case 'boolean':
			return value ? 'true' : 'false';
		case 'object':
		case 'array':
			return btoa(JSON.stringify(value));
		case 'number':
		case 'string':
		default:
			return value.toString();
	}
}

export class StoreInterfaceImpl<STORE extends IndexableObject> extends StoreInterface<STORE> {
	private readonly qs: QueryParamsManager;

	private readonly config: PersistQueryParamsConfig<STORE>;

	constructor(config: PersistQueryParamsConfig<STORE>) {
		super();
		this.qs = QueryParamsManagerImpl.getInstance();
		this.config = config;
	}

	private getConfigAndKeyName(key: keyof STORE): {
		fieldConfig?: PersistQueryParamsKeyConfig;
		fieldKey?: string;
		sessionStorageKey?: string;
	} {
		const fieldConfig = this.config[key];
		const fieldKey = fieldConfig?.keyName ?? (key as string);
		return {
			fieldConfig,
			fieldKey,
			sessionStorageKey: fieldConfig?.sessionStorageKeyPrefix
				? `${fieldConfig?.sessionStorageKeyPrefix}-${fieldKey}`
				: undefined,
		};
	}

	getItem(key: keyof STORE): ValueType | undefined {
		const { fieldConfig, fieldKey, sessionStorageKey } = this.getConfigAndKeyName(key);
		if (!fieldConfig || !fieldKey) return undefined;
		const storedValueFromQuery = this.qs.get(fieldKey);
		if (storedValueFromQuery !== undefined) {
			if (sessionStorageKey) window.sessionStorage.setItem(sessionStorageKey, storedValueFromQuery);
			return deserializeValue(storedValueFromQuery, fieldConfig);
		}
		if (sessionStorageKey) {
			const storeValueFromSession = window.sessionStorage.getItem(sessionStorageKey);
			if (storeValueFromSession !== null) {
				return deserializeValue(storeValueFromSession, fieldConfig);
			}
		}
		return undefined;
	}

	setItem(key: keyof STORE, value: ValueType): void {
		const { fieldConfig, fieldKey, sessionStorageKey } = this.getConfigAndKeyName(key);
		if (!fieldConfig || !fieldKey) return;
		// If the value is considered empty we remove the key from the query to keep it clean
		if (isValueEmpty(value, fieldConfig)) {
			this.qs.delete(fieldKey);
			if (sessionStorageKey) window.sessionStorage.removeItem(sessionStorageKey);
			// Otherwise we serialize the value and store it in the query (and session storage)
		} else {
			const serializedValue = serializeValue(value, fieldConfig);
			this.qs.set(fieldKey, serializedValue);
			if (fieldConfig.defaultValue !== undefined && isEqual(value, fieldConfig.defaultValue)) {
				this.qs.delete(fieldKey);
				if (sessionStorageKey) window.sessionStorage.removeItem(sessionStorageKey);
			} else {
				this.qs.set(fieldKey, serializedValue);
				if (sessionStorageKey) window.sessionStorage.setItem(sessionStorageKey, serializedValue);
			}
		}
	}

	removeItem(key: keyof STORE) {
		const { fieldKey, sessionStorageKey } = this.getConfigAndKeyName(key);
		if (!fieldKey) return;
		this.qs.delete(fieldKey);
		if (sessionStorageKey) window.sessionStorage.removeItem(sessionStorageKey);
	}

	hideItem(key: keyof STORE) {
		const { fieldKey } = this.getConfigAndKeyName(key);
		if (!fieldKey) return;
		this.qs.delete(fieldKey);
	}
}
