import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ApolloProvider } from '@apollo/client';
import { CookiesProvider } from 'react-cookie';
import 'react-datepicker/dist/react-datepicker.css';
import { useTokenGetter, InteractionRequiredModal } from '@warehouse/auth';
import { Outlet } from 'react-router-dom';
import { MaterialDesignContent, SnackbarProvider } from 'notistack';
import { apolloAdapter } from '@warehouse/shared/infra';
import { useQueryParamsManager } from '@warehouse/shared/util';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { EditorProvider } from './views/profiles/editor/EditorContext';
import Navigation from './components/library/Navigation';
import CustomSnackbar from './components/library/CustomSnackbar';

const AppWrapper = styled.div`
	display: flex;
	flex-basis: 100vh;
	flex-direction: column;
	height: 100vh;
`;

const Wrapper = styled.div`
	align-items: stretch;
	box-sizing: border-box;
	display: flex;
	flex-grow: 1;
	overflow: hidden;
	position: relative;
`;

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
	'&.notistack-MuiContent-success': {
		backgroundColor: '#DBEFDC',
		color: '#004D03',
		fontFamily: 'Roboto',
		fontWeight: 400,
		boxShadow: 'rgba(0,0,0, 0.2) 0px 4px 12px',
		borderRadius: '8px',
	},
	'&.notistack-MuiContent-error': {
		backgroundColor: '#FFDCDC',
		color: '#8C0000',
		fontFamily: 'Roboto',
		fontWeight: 400,
		boxShadow: 'rgba(0,0,0, 0.2) 0px 4px 12px',
		borderRadius: '8px',
	},
	'&.notistack-MuiContent-warning': {
		backgroundColor: '#FEF0CC',
		color: '#8C5E00',
		fontFamily: 'Roboto',
		fontWeight: 400,
		boxShadow: 'rgba(0,0,0, 0.2) 0px 4px 12px',
		borderRadius: '8px',
	},
}));

const queryClient = new QueryClient();

function App() {
	// Hooks
	useQueryParamsManager();
	const { tokenGetter, interactionRequired, error, retry } = useTokenGetter();

	// Effects
	useEffect(() => {
		apolloAdapter.setTokenGetter(tokenGetter);
	}, [tokenGetter]);

	return (
		<QueryClientProvider client={queryClient}>
			<CookiesProvider>
				<EditorProvider>
					<ApolloProvider client={apolloAdapter.client}>
						<SnackbarProvider
							Components={{
								success: StyledMaterialDesignContent,
								error: StyledMaterialDesignContent,
								warning: StyledMaterialDesignContent,
								customSnackbar: CustomSnackbar,
							}}
							maxSnack={5}
						>
							<AppWrapper className="App">
								<Navigation />
								<Wrapper>
									<Outlet />
								</Wrapper>
							</AppWrapper>
							<InteractionRequiredModal open={interactionRequired} error={error} retry={retry} />
						</SnackbarProvider>
					</ApolloProvider>
				</EditorProvider>
			</CookiesProvider>
		</QueryClientProvider>
	);
}

export default App;
