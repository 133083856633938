import { definePersistentQueryParamStore } from '@warehouse/shared/util';

export interface PaginatorStore {
	totalPages: number;
	totalCount: number;
	page: number;
	perPage: number;
	actions: {
		nextPage(): void;
		prevPage(): void;
		setPage(page: number): void;
		setPerPage(perPage: number): void;
		setTotalPages(totalPages: number): void;
		setTotalCount(totalCount: number): void;
	};
}

export const [PaginatorProvider, usePaginatorStore] = definePersistentQueryParamStore<PaginatorStore>((set, get) => ({
	totalPages: 1,
	totalCount: 0,
	page: 1,
	perPage: 10,
	actions: {
		setPage(page: number) {
			set((prev) => ({ page: Math.min(Math.max(1, page), prev.totalPages) }));
		},
		nextPage() {
			this.setPage(get().page + 1);
		},
		prevPage() {
			this.setPage(get().page - 1);
		},
		setPerPage(perPage: number) {
			set({ perPage, page: 1 });
		},
		setTotalPages(totalPages: number) {
			set({ totalPages });
		},
		setTotalCount(totalCount: number) {
			set({ totalCount });
		},
	},
}));

export const paginatorStoreSelector = {
	actions: (state: PaginatorStore) => state.actions,
	totalCount: (state: PaginatorStore) => state.totalCount,
	page: (state: PaginatorStore) => state.page,
	perPage: (state: PaginatorStore) => state.perPage,
	totalPages: (state: PaginatorStore) => state.totalPages,
};
