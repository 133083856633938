import { alpha, Typography } from '@mui/material';
import React from 'react';
import { TitleFull as Title, isDerivedFrom, isEpisodeOf, isSeasonOf } from '@warehouse/title/core';
import styled, { css } from 'styled-components';
import { WorkType } from '@warehouse/title/worktype';
import {
	getPluralizedLabelWorkType,
	getRelationshipTypeLabel,
	getWorkType,
	labelFromWorkType,
} from '@warehouse/title/infra';
import { TitleCard } from '@warehouse/title/ui';
import { useTitle } from '@warehouse/title/domain';
import { FieldWrapper } from './style';
import workTypeJson from '../../../assets/json-administration-profiles/workTypes.json';
import TitleTooltip from '../../../components/titles/TitleTooltip';
import NxLoader from '../../../components/library/NxLoader';
import { Parent } from '../../../../libs/title/core/models/parent';

type PrimaryParentProps = {
	title: Title;
};

const TypographyStyled = styled(Typography)(
	({ theme }) => css`
		color: ${alpha(theme.palette.text.secondary, 0.5)} !important;
	`,
);

const CustomLoaderWrapper = styled.div(
	() => css`
		align-items: center;
		display: flex;
		height: 50px;
	`,
);

export function PrimaryParent({ title }: PrimaryParentProps) {
	const { parents, workType } = title.metadata.coreMetadata.basic;
	const titleShouldNotHaveRequiredParent = !workTypeJson.find((wt) => wt.value === workType)?.parentRequired || false;

	if (!parents || titleShouldNotHaveRequiredParent) return <NoParents workType={workType} />;

	const requiredParent = getRequiredParentByWorkType(getWorkType(workType), parents);

	if (!requiredParent) throw new Error(`No required parent found for work type ${workType} in title ${title.uuid}`);

	const parentUuid = requiredParent.parentContentId;
	const parentRelationshipType = requiredParent.relationshipType;
	const relationshipTypeLabel = getRelationshipTypeLabel(parentRelationshipType);
	const parentQueryOutput = useTitle({ uuid: parentUuid });

	if (parentQueryOutput.isPending) {
		return (
			<PrimaryParentWrapper relationshipTypeLabel={relationshipTypeLabel}>
				<CustomLoaderWrapper>
					<NxLoader size={30} />
				</CustomLoaderWrapper>
			</PrimaryParentWrapper>
		);
	}

	if (!parentQueryOutput.data) throw new Error(`Get title query failed for title ${parentUuid}`);

	const titleFull = parentQueryOutput.data;

	return (
		<PrimaryParentWrapper relationshipTypeLabel={relationshipTypeLabel}>
			<TitleCard variant="colored" titleLight={titleFull.readonly} />
		</PrimaryParentWrapper>
	);
}

function NoParents({ workType }: { workType: string }) {
	const workTypeLabel = labelFromWorkType(getWorkType(workType))?.toLocaleLowerCase();
	const pluralizedWorkTypeLabel = getPluralizedLabelWorkType(workTypeLabel);

	return (
		<PrimaryParentWrapper>
			<TypographyStyled variant="buttonMediumRegular">
				{`All parent relationships for ${pluralizedWorkTypeLabel} are managed in the Relationship tab.`}
			</TypographyStyled>
		</PrimaryParentWrapper>
	);
}

function PrimaryParentWrapper({
	children,
	relationshipTypeLabel,
}: {
	children: React.ReactNode;
	relationshipTypeLabel?: string;
}) {
	return (
		<FieldWrapper className="field-wrapper">
			<Typography variant="buttonLargeMedium">
				Required Parent {relationshipTypeLabel ? `(${relationshipTypeLabel})` : ''}
				<TitleTooltip path="coreMetadata.basic.parent" />
			</Typography>
			{children}
		</FieldWrapper>
	);
}

function getRequiredParentByWorkType(workType: WorkType, parents: Parent[]): Parent | undefined {
	if ([WorkType.Edit, WorkType.Manifestation].includes(workType))
		return parents.find((parent) => parent.relationshipType === isDerivedFrom());

	if (workType === WorkType.Season) return parents.find((parent) => parent.relationshipType === isSeasonOf());

	if (workType === WorkType.Episode) return parents.find((parent) => parent.relationshipType === isEpisodeOf());

	throw new Error(`WorkType ${workType} does not have required parent`);
}
