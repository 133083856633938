import React, { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { v4 as uuid } from 'uuid';
import { DialogContent } from '@mui/material';
import { mdiCheckboxMarkedCircle, mdiCloseCircle, mdiFilterVariant, mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import MUIDialogActions, { DialogActionsProps } from '@mui/material/DialogActions';
import Dialog from '../../library/Dialog';
import DialogTitle, { CloseButton } from '../../library/DialogTitle';
import Button from '../../library/Button';
import ConditionBlock from './ConditionBlock';
import FilterItem from './FilterItem';
import { FilterField, FilterStepWithId, FilterWithId, isFilter, isStep } from '../../../utils/table/filtersUtils';
import Dropdown from '../../library/Dropdown/Dropdown';

type FilterModalProps = {
	open: boolean;
	handleClose: (value?: FilterStepWithId) => void;
	fields: FilterField[];
	filterState?: FilterStep;
	onSubmit: (value?: FilterStepWithId) => void;
};

const ContentWrapper = styled.div(
	({ theme }) => css`
		max-height: 60vh;
		overflow-y: scroll;
		padding: ${theme.spacing(2)};
	`,
);

const ContentWrapperNoScroll = styled.div(
	({ theme }) => css`
		padding: ${theme.spacing(2)};
	`,
);

const AddingRowWrapper = styled.div(
	({ theme }) => css`
		padding: ${theme.spacing(2)};
		padding-top: 0;
	`,
);

const DialogActions = styled(MUIDialogActions)<DialogActionsProps>(
	({ theme }) => css`
		justify-content: space-between;
		padding: ${theme.spacing(2)} ${theme.spacing(1)};
		padding-top: ${theme.spacing(1)};

		> div button {
			margin: 0 ${theme.spacing(1)};
		}
	`,
);

export default function FiltersModal(props: FilterModalProps) {
	const { fields, filterState, open, handleClose, onSubmit } = props;
	const fieldsLabels = useMemo(() => fields.map((f) => ({ label: f.label, value: f.id })), [fields]);
	const [localValues, setLocalValues] = useState<FilterStepWithId>();
	const [firstValue, setFirstValue] = useState<string | null>(null);
	const [isValid, setIsValid] = useState<boolean>(false);

	const handleSubmit = () => {
		if (localValues) {
			onSubmit(localValues);
		} else onSubmit(undefined);
	};

	const handleClear = () => {
		setLocalValues({
			id: uuid(),
			step: {
				combinationOperator: 'AND',
				filters: [],
			},
		});
	};

	const editStepConditionalOperator = (id: string, value: 'AND' | 'OR') => {
		if (localValues) {
			const tmp: FilterStepWithId = structuredClone(localValues);
			let found: boolean = false;

			const findItem = (items: (FilterStepWithId | FilterWithId)[]) => {
				if (!found) {
					items.forEach((i, index) => {
						const filter = items[index];
						if (isStep(filter)) {
							if (filter.id === id) {
								filter.step.combinationOperator = value;
								// eslint-disable-next-line no-param-reassign
								items[index] = filter;
								found = true;
							} else findItem(filter.step.filters);
						}
					});
				}
				return items;
			};
			if (tmp.id === id) tmp.step.combinationOperator = value;
			else tmp.step.filters = findItem(tmp.step.filters);
			setLocalValues({ ...tmp });
		}
	};
	const editFieldOnItem = (id: string, value: string) => {
		if (localValues) {
			const tmp: FilterStepWithId = structuredClone(localValues);
			const field = fields.find((f) => f.id === value);

			let found: boolean = false;

			const findItem = (items: (FilterStepWithId | FilterWithId)[]) => {
				if (!found) {
					items.forEach((i, index) => {
						const filter = items[index];
						if (isStep(filter)) {
							findItem(filter.step.filters);
						}
						if (i.id === id && isFilter(filter)) {
							if (field?.type === 'date')
								filter.dateAuxiliary = {
									operator: 'theDate',
								};
							filter.field = value;
							filter.operator = undefined;
							filter.value = '';
							// eslint-disable-next-line no-param-reassign
							items[index] = filter;
							found = true;
						}
					});
				}
				return items;
			};

			tmp.step.filters = findItem(tmp.step.filters);
			setLocalValues({ ...tmp });
		}
	};

	const editDateAuxiliaryOnItem = (id: string, value: DateAuxiliary) => {
		if (localValues) {
			const tmp: FilterStepWithId = structuredClone(localValues);

			let found: boolean = false;

			const findItem = (items: (FilterStepWithId | FilterWithId)[]) => {
				if (!found) {
					items.forEach((i, index) => {
						const filter = items[index];
						if (isStep(filter)) {
							findItem(filter.step.filters);
						}
						if (i.id === id && isFilter(filter)) {
							filter.dateAuxiliary = value;
							// eslint-disable-next-line no-param-reassign
							items[index] = filter;
							found = true;
						}
					});
				}
				return items;
			};

			tmp.step.filters = findItem(tmp.step.filters);
			setLocalValues({ ...tmp });
		}
	};

	const editOperatorOnItem = (id: string, value: string) => {
		if (localValues) {
			const tmp: FilterStepWithId = structuredClone(localValues);
			let found: boolean = false;

			const findItem = (items: (FilterStepWithId | FilterWithId)[]) => {
				if (!found) {
					items.forEach((i, index) => {
						const filter = items[index];
						if (isStep(filter)) {
							findItem(filter.step.filters);
						}
						if (i.id === id && isFilter(filter)) {
							filter.operator = value as keyof FilterOperators;
							if (value === 'isAnyOf' || value === 'isNoneOf') {
								filter.value = [];
							} else filter.value = '';
							// eslint-disable-next-line no-param-reassign
							items[index] = filter;
							found = true;
						}
					});
				}
				return items;
			};

			tmp.step.filters = findItem(tmp.step.filters);
			setLocalValues({ ...tmp });
		}
	};

	const editValueOnItem = (id: string, value: string | string[]) => {
		if (localValues) {
			const tmp: FilterStepWithId = structuredClone(localValues);
			let found: boolean = false;

			const findItem = (items: (FilterStepWithId | FilterWithId)[]) => {
				if (!found) {
					items.forEach((i, index) => {
						const filter = items[index];
						if (isStep(filter)) {
							findItem(filter.step.filters);
						}
						if (i.id === id && isFilter(filter)) {
							filter.value = value;
							// eslint-disable-next-line no-param-reassign
							items[index] = filter;
							found = true;
						}
					});
				}
				return items;
			};

			tmp.step.filters = findItem(tmp.step.filters);
			setLocalValues({ ...tmp });
		}
	};

	const deleteItem = (id: string) => {
		if (localValues) {
			const tmp: FilterStepWithId = structuredClone(localValues);
			let found: boolean = false;

			const findItem = (items: (FilterStepWithId | FilterWithId)[]) => {
				if (!found) {
					items.forEach((i, index, obj) => {
						const filter = items[index];
						if (i.id === id) {
							found = true;
							obj.splice(index, 1);
						} else if (isStep(filter)) {
							findItem(filter.step.filters);
						}
					});
				}
				return items;
			};

			if (tmp.id === id) handleClear();
			else {
				tmp.step.filters = findItem(tmp.step.filters);
				setLocalValues({ ...tmp });
			}
		}
	};

	const addItem = (id: string) => {
		const emptyItem = {
			field: fields ? fields[0].id : '',
			operator: undefined,
			value: '',
			id: uuid(),
		};

		if (localValues) {
			const tmp: FilterStepWithId = structuredClone(localValues);
			let found: boolean = false;

			const findItem = (items: (FilterStepWithId | FilterWithId)[]) => {
				if (!found) {
					items.forEach((i, index) => {
						const filter = items[index];
						if (i.id === id && isStep(filter)) {
							found = true;
							filter.step.filters.push(emptyItem);
							// eslint-disable-next-line no-param-reassign
							items[index] = filter;
							found = true;
						} else if (isStep(filter)) {
							findItem(filter.step.filters);
						}
					});
				}
				return items;
			};

			if (tmp.id === id) {
				tmp.step.filters.push(emptyItem);
			} else {
				tmp.step.filters = findItem(tmp.step.filters);
			}
			setLocalValues({ ...tmp });
		}
	};

	const addStep = (id: string) => {
		const newStep: FilterStepWithId = {
			id: uuid(),
			step: {
				combinationOperator: 'AND',
				filters: [
					{
						field: fields ? fields[0].id : '',
						operator: undefined,
						value: '',
						id: uuid(),
					},
				],
			},
		};
		if (localValues) {
			const tmp: FilterStepWithId = structuredClone(localValues);
			let found: boolean = false;

			const findItem = (items: (FilterStepWithId | FilterWithId)[]) => {
				if (!found) {
					items.forEach((i, index) => {
						const filter = items[index];
						if (i.id === id && isStep(filter)) {
							found = true;
							filter.step.filters.push(newStep);
							// eslint-disable-next-line no-param-reassign
							items[index] = filter;
							found = true;
						} else if (isStep(filter)) {
							findItem(filter.step.filters);
						}
					});
				}
				return items;
			};

			if (tmp.id === id) {
				tmp.step.filters.push(newStep);
			} else {
				tmp.step.filters = findItem(tmp.step.filters);
			}
			setLocalValues({ ...tmp });
		}
	};

	// Add UUIDs on every tree item for easy editing
	const addIdsToFilters = (filters: Array<FilterWithId | FilterStepWithId | Filter | FilterStep>) => {
		const newFilters: (FilterWithId | FilterStepWithId | Filter | FilterStep)[] = [...filters].map((i) => ({
			...i,
			id: uuid(),
		}));
		newFilters.forEach((item) => {
			if (isStep(item)) {
				// eslint-disable-next-line no-param-reassign
				item.step.filters = addIdsToFilters(item.step.filters);
			}
		});
		return newFilters;
	};

	useEffect(() => {
		if (filterState) {
			const res: {
				id: string;
				step: {
					combinationOperator: 'AND' | 'OR';
					filters: Array<FilterWithId | FilterStepWithId | Filter | FilterStep>;
				};
			} = { ...structuredClone(filterState), id: uuid() };
			res.step.filters = addIdsToFilters(res.step.filters);
			setLocalValues(res);
		}
	}, [filterState]);

	// Validation
	useEffect(() => {
		setIsValid(true);
		if (localValues) {
			const browseItems = (items: (FilterStepWithId | FilterWithId)[]) => {
				items.forEach((i, index) => {
					const filter = items[index];
					if (isStep(filter)) {
						browseItems(filter.step.filters);
					}
					if (isFilter(filter)) {
						const field = fields.find((f) => f.id === filter.field);

						if (field?.type === 'date') {
							// Missing operator or field
							if (filter.operator !== 'isEmpty' && (!filter.field || !filter.operator)) setIsValid(false);
							// The date and no date value
							if (filter.dateAuxiliary?.operator === 'theDate' && !filter.value?.toString().length) setIsValid(false);
							// Missing number when relative date
							if (
								(filter.dateAuxiliary?.operator === 'daysInThePast' ||
									filter.dateAuxiliary?.operator === 'daysInTheFuture') &&
								!filter.dateAuxiliary.number
							)
								setIsValid(false);
						}
						if (
							field?.type !== 'date' &&
							filter.operator !== 'isEmpty' &&
							(!filter.field || !filter.operator || !filter.value?.toString().length)
						) {
							setIsValid(false);
						}
						if (filter.operator === 'isEmpty' && !filter.field) setIsValid(false);
					}
				});
				return items;
			};

			browseItems(localValues.step.filters);
		}
	}, [localValues]);

	// Render tree elements
	const getFilterItem = (i: FilterWithId) => {
		const field = fields.find((f) => f.id === i.field);
		const tmpDateAuxiliary =
			field?.type === 'date'
				? {
						operator: 'theDate',
						number: 0,
				  }
				: undefined;
		if (field) {
			return (
				<FilterItem
					key={i.id}
					type={field.type}
					fieldOptions={fieldsLabels}
					fieldValue={i.field}
					setFieldValue={(value) => {
						if (i.id) editFieldOnItem(i.id, value || '');
					}}
					operatorValue={i.operator || null}
					setOperatorValue={(value) => {
						if (i.id) editOperatorOnItem(i.id, value || '');
					}}
					value={i.value}
					setValue={(value) => {
						if (i.id) editValueOnItem(i.id, value || '');
					}}
					valueOptions={field.options}
					dateAuxiliary={i.dateAuxiliary || tmpDateAuxiliary}
					setDateAuxiliary={(value) => {
						if (i.id) editDateAuxiliaryOnItem(i.id, value);
					}}
					onDelete={() => {
						if (i.id) deleteItem(i.id);
					}}
				/>
			);
		}

		return null;
	};

	const getConditionBlock = (item: FilterStepWithId | FilterWithId, level: number) => {
		let children;

		if (isStep(item)) {
			children = item.step.filters.map((i: FilterStepWithId | FilterWithId) => {
				if (isStep(i)) {
					return getConditionBlock(i, level + 1);
				}
				return getFilterItem(i);
			});

			return (
				<ConditionBlock
					key={item.id}
					conditionalOperator={item.step.combinationOperator}
					level={level + 1}
					setConditionalOperator={(v) => {
						if (item.id) editStepConditionalOperator(item.id, v);
					}}
					onAddItem={() => {
						if (item.id) addItem(item.id);
					}}
					onAddStep={() => {
						if (item.id) addStep(item.id);
					}}
					onDelete={() => {
						if (item.id) deleteItem(item.id);
					}}
				>
					{children}
				</ConditionBlock>
			);
		}
		return null;
	};

	return (
		<Dialog open={open} onClose={() => handleClose(localValues)} maxWidth={localValues ? 'lg' : 'md'} fullWidth>
			<DialogTitle>
				<Icon path={mdiFilterVariant} size="24px" />
				Filter by
				<CloseButton onClose={() => handleClose(localValues)} />
			</DialogTitle>
			<DialogContent>
				{!localValues ? (
					<ContentWrapperNoScroll>
						<AddingRowWrapper>
							<Dropdown
								withSearch
								label="Pick a field to filter by"
								value={firstValue}
								onChange={(value) => {
									if (value) {
										const field = fields.find((f) => f.id === value);

										setLocalValues({
											id: uuid(),
											step: {
												combinationOperator: 'AND',
												filters: [
													{
														id: uuid(),
														field: value,
														operator: undefined,
														value: '',
														dateAuxiliary:
															field?.type === 'date'
																? {
																		operator: 'theDate',
																		number: 0,
																  }
																: undefined,
													},
												],
											},
										});
										setFirstValue(null);
									}
								}}
								options={fieldsLabels}
							/>
						</AddingRowWrapper>
					</ContentWrapperNoScroll>
				) : null}
				{localValues ? <ContentWrapper>{getConditionBlock(localValues, 0)}</ContentWrapper> : null}
			</DialogContent>
			<DialogActions>
				<div>
					<Button
						nxstyle="secondary-black"
						endIcon={<Icon path={mdiClose} size="16px" />}
						onClick={() => handleClear()}
					>
						Clear
					</Button>
				</div>
				<div>
					<Button
						nxstyle="secondary-blue"
						endIcon={<Icon path={mdiCloseCircle} size="16px" />}
						onClick={() => handleClose(localValues)}
					>
						Cancel
					</Button>

					<Button
						disabled={!isValid}
						nxstyle="primary-blue"
						endIcon={<Icon path={mdiCheckboxMarkedCircle} size="16px" />}
						onClick={handleSubmit}
					>
						Apply
					</Button>
				</div>
			</DialogActions>
		</Dialog>
	);
}
