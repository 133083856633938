/**
 * Assert unreachable gives type safety to switch statement or sequence of ifs that should be exhaustive.
 * It is useful to make sure that all cases are handled now, and that future cases won't be forgotten (as a type error
 * will show at compile time).
 *
 * Example usage:
 *
 * type RelationshipType = 'isderivedfrom' | 'isepisodeof' | 'isseasonof';
 *
 * function getRelationshipTypeLabel(relationshipType: RelationshipType) {
 * 	switch (relationshipType) {
 * 		case 'isderivedfrom':
 * 			return 'Derived from';
 * 		case 'isepisodeof':
 * 			return 'Episode of';
 * 		default:
 * 			assertUnreachable(relationshipType);
 * 		                    // ^ Type Error: 'isseasonof' is not assignable to type 'never'
 * 	}
 * }
 */
export function assertUnreachable(x: never): void {
	console.error(`Unreachable case: ${x}`);
}
