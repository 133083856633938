import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';

// TYPES
import {
	MadeForRegion,
	MadeForRegionExclude,
	Region,
} from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Region';
import { OptionalInherited } from '@nexspec/warehouse-shared-types/src/titles/TitleMetadata/Inherited';
import { titleEditorStoreSelector, useTitleEditorStore } from '@warehouse/title/domain';
import { titlePaths } from '@warehouse/title/core';

// HOOKS
import { useTerritories } from '@warehouse/global-entities/core';
import useTitleAutoSave from '../../../../src/views/title/hooks/useTitleAutoSave/useTitleAutoSave';

// LIBRARY
import ChipDisplay from '../../../../src/components/library/ChipDisplay';
import TitleEditableField from '../../../../src/components/titles/TitleEditableField';
import { SelectDropdownWrapper } from '../../../../src/components/titles/TitleOverview/fields/style';
import { TerritoriesSelector } from '../../../../src/components/titles/TerritoriesSelector';
import './MadeForRegionField.css';
import { fromBasic } from '../../../../src/utils/titleGetProperty';
import useMemoJsonPath from '../../../../src/views/title/hooks/useMemoJsonPath';
import IncludeExcludeBottomAction from '../../../../src/components/titles/IncludeExcludeBottomAction';
import { isInherited as isInheritedFunction } from '../../core/title-inheritance';

function optionalInheritedArrayIsFilled(
	madeForRegions: OptionalInherited<MadeForRegion[] | MadeForRegionExclude[]> | undefined,
) {
	return madeForRegions && (madeForRegions?.displayValue?.length || []) > 0;
}

export function MadeForRegionField() {
	const title = useTitleEditorStore(titleEditorStoreSelector.titleRequired);

	const madeForRegions = useTitleAutoSave<{
		madeForRegions: OptionalInherited<MadeForRegion[]>;
		madeForRegionsExclude: OptionalInherited<MadeForRegionExclude[]>;
	}>({
		label: 'Made For Regions',
		path: useMemoJsonPath(fromBasic(['versionIntent'])),
		onSave: (value, addToQueue, addToQueueParams) => {
			if (!title?.uuid) return;
			addToQueue({
				...addToQueueParams,
				mutation: 'edits',
				edits: [
					{
						value: value?.madeForRegions?.displayValue || [],
						path: fromBasic(['versionIntent', 'madeForRegions']),
					},
					{
						value: value?.madeForRegionsExclude?.displayValue || [],
						path: fromBasic(['versionIntent', 'madeForRegionsExclude']),
					},
				],
			});
		},
	});
	const { getKey, getLabel } = useTerritories();
	const [radioGroupValue, setRadioGroupValue] = useState<string>('includes');

	useEffect(() => {
		setRadioGroupValue(
			optionalInheritedArrayIsFilled(madeForRegions?.value?.madeForRegionsExclude) ? 'excludes' : 'includes',
		);
	}, [madeForRegions?.value?.madeForRegionsExclude]);

	const handleSwitch = (e: ChangeEvent<HTMLInputElement>) => {
		const newMode = e.target.value;
		setRadioGroupValue(newMode);

		const keyToEmpty = newMode === 'includes' ? 'madeForRegionsExclude' : 'madeForRegions';
		const keyToFill = newMode === 'includes' ? 'madeForRegions' : 'madeForRegionsExclude';

		madeForRegions.setValue((prev) => ({
			...prev,
			[keyToEmpty]: {
				...prev[keyToEmpty],
				displayValue: [],
			},
			[keyToFill]: {
				...prev[keyToFill],
				displayValue: prev[keyToEmpty]?.displayValue || [],
			},
		}));
	};

	const onChange = (values: string[]) => {
		const mappedValues = values.map((value) => ({
			[getKey(value)]: value,
		}));

		const key = radioGroupValue === 'includes' ? 'madeForRegions' : 'madeForRegionsExclude';
		madeForRegions?.setValue((prev) => ({
			...prev,
			[key]: {
				...prev?.[key],
				displayValue: mappedValues.length > 0 ? mappedValues : prev?.[key].inherited?.value || [],
			},
		}));
	};

	const dropdownValues = useMemo(() => {
		const mapFn = (coo: Region) => coo.country || coo.countryRegion || '';
		if (optionalInheritedArrayIsFilled(madeForRegions?.value?.madeForRegionsExclude)) {
			return madeForRegions?.value?.madeForRegionsExclude?.displayValue?.map(mapFn) || [];
		}
		if (optionalInheritedArrayIsFilled(madeForRegions?.value?.madeForRegions)) {
			return madeForRegions?.value?.madeForRegions?.displayValue?.map(mapFn) || [];
		}
		return [];
	}, [madeForRegions?.value?.madeForRegions?.displayValue, madeForRegions?.value?.madeForRegionsExclude?.displayValue]);

	const chipDisplayValues = useMemo(() => {
		const mapFn = (coo: Region) => getLabel(coo.country || coo.countryRegion || '');
		if (optionalInheritedArrayIsFilled(madeForRegions.readOnlyValue?.madeForRegionsExclude)) {
			return madeForRegions.readOnlyValue?.madeForRegionsExclude?.displayValue?.map(mapFn) || [];
		}
		if (optionalInheritedArrayIsFilled(madeForRegions.readOnlyValue?.madeForRegions)) {
			return madeForRegions.readOnlyValue?.madeForRegions?.displayValue?.map(mapFn) || [];
		}
		return [];
	}, [
		madeForRegions.readOnlyValue?.madeForRegions?.displayValue,
		madeForRegions.readOnlyValue?.madeForRegionsExclude?.displayValue,
	]);

	const { isInherited: isInheritedMadeForRegions } = isInheritedFunction({
		inheritedObject: madeForRegions.value?.madeForRegions,
	});
	const { isInherited: isInheritedMadeForRegionsExclude } = isInheritedFunction({
		inheritedObject: madeForRegions.value?.madeForRegionsExclude,
	});

	const isInherited = isInheritedMadeForRegions || isInheritedMadeForRegionsExclude;

	return (
		<TitleEditableField
			labelStyle={
				radioGroupValue === 'excludes'
					? {
							fontSize: '13.3px',
					  }
					: undefined
			}
			label={radioGroupValue === 'includes' ? 'Made For Regions:' : 'Ex Made For Regions:'}
			path={titlePaths.madeForRegions}
			jumpToParentButtonDisabled
			tooltipDisabled
			readComponent={
				<ChipDisplay
					allowOverflow
					variant={isInherited ? 'prefilled' : 'default'}
					horizontalPadding={0}
					calcWidth="calc(100% - 170px)"
					values={chipDisplayValues}
					height={26}
				/>
			}
			editComponent={
				<SelectDropdownWrapper style={{ width: 'calc(100% - 145px)' }}>
					<TerritoriesSelector<string[]>
						ariaLabel="Made For Regions"
						disabledMargin
						variant={isInherited ? 'prefilled' : 'default'}
						multiple
						onChange={onChange}
						onBlur={() => madeForRegions?.commit()}
						value={dropdownValues}
						bottomActions={
							<IncludeExcludeBottomAction
								value={radioGroupValue}
								defaultValue={
									optionalInheritedArrayIsFilled(madeForRegions?.value?.madeForRegionsExclude) ? 'excludes' : 'includes'
								}
								onChange={handleSwitch}
							/>
						}
						height={38}
						listItemHeight={38}
						chipHeight={16}
					/>
				</SelectDropdownWrapper>
			}
		/>
	);
}
