import React, { ReactNode, useMemo } from 'react';
import { PaginatorProvider, PaginatorStore, SearchProvider, SearchStore } from '@warehouse/shared/ui';
import { PersistQueryParamsConfig } from '../../shared/util/persist-query-param-store';
import { SortStore, SortProvider } from '../../shared/ui/sort/sort.store';
import { defaultSorters } from './default-sorters';

export function TitleReportSearchStoreProvider({ children }: { children: ReactNode }) {
	const storeConfig = useMemo(
		(): PersistQueryParamsConfig<SearchStore> => ({
			searchValue: {
				defaultValue: '',
				keyName: 'titleSearch',
			},
		}),
		[],
	);

	return <SearchProvider config={storeConfig}>{children}</SearchProvider>;
}

export function TitleReportPaginationStoreProvider({ children }: { children: ReactNode }) {
	const storeConfig = useMemo(
		(): PersistQueryParamsConfig<PaginatorStore> => ({
			page: {
				type: 'number',
				defaultValue: 1,
			},
			perPage: {
				type: 'number',
				defaultValue: 50,
				sessionStorageKeyPrefix: 'title-report',
			},
		}),
		[],
	);
	return <PaginatorProvider config={storeConfig}>{children}</PaginatorProvider>;
}

export function TitleReportSortStoreProvider({ children }: { children: ReactNode }) {
	const storeConfig = useMemo(
		(): PersistQueryParamsConfig<SortStore> => ({
			sort: {
				type: 'array',
				defaultValue: defaultSorters,
				sessionStorageKeyPrefix: 'title-report',
			},
		}),
		[],
	);
	return <SortProvider config={storeConfig}>{children}</SortProvider>;
}

export function TitleReportStoresProvider({ children }: { children: ReactNode }) {
	return (
		<TitleReportSearchStoreProvider>
			<TitleReportPaginationStoreProvider>
				<TitleReportSortStoreProvider>{children}</TitleReportSortStoreProvider>
			</TitleReportPaginationStoreProvider>
		</TitleReportSearchStoreProvider>
	);
}
