import React, { ReactNode, useMemo } from 'react';
import { PaginatorProvider, PaginatorStore } from '@warehouse/shared/ui';
import { PersistQueryParamsConfig } from '../../shared/util/persist-query-param-store';

export function MultipleTitleSearchConfig({ children }: { children: ReactNode }) {
	const paginationStoreConfig = useMemo(
		(): PersistQueryParamsConfig<PaginatorStore> => ({
			page: {
				type: 'number',
				defaultValue: 1,
			},
			perPage: {
				type: 'number',
				defaultValue: 10,
			},
		}),
		[],
	);

	return <PaginatorProvider config={paginationStoreConfig}>{children}</PaginatorProvider>;
}
